"use client";

import { navData } from "@constants/menu";
import {
  MotionValue,
  motion,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";
import { useRef } from "react";
import { useMediaQuery } from "usehooks-ts";
import { Tooltip, TooltipContent, TooltipTrigger } from "./Tooltip";

export default function Dock() {
  const mouseX = useMotionValue(Infinity);
  const isMobile = useMediaQuery("only screen and (max-width: 768px)");

  return (
    <motion.div
      onMouseMove={(e) => mouseX.set(e.pageX)}
      onMouseLeave={() => mouseX.set(Infinity)}
      className="mx-auto flex items-end gap-2 rounded-full bg-white px-4 py-3 group shadow-2xl w-fit h-[84px]"
    >
      {navData.map((el, i) => (
        // had to add this because somehow, coming from home to any page which has mobile menu wasn't activating hamburger
        <a href={el.href} className="block" key={i} data-astro-reload>
          <AppIcon
            mouseX={mouseX}
            key={i}
            tooltipName={el.text}
            isMobile={isMobile}
          >
            {el.icon}
          </AppIcon>
        </a>
      ))}
    </motion.div>
  );
}

function AppIcon({
  mouseX,
  children,
  tooltipName,
  isMobile,
}: {
  mouseX: MotionValue;
  children: React.ReactNode;
  tooltipName: string;
  isMobile: boolean;
}) {
  const ref = useRef<HTMLDivElement | null>(null);

  const distance = useTransform(mouseX, (val) => {
    const bounds = ref.current?.getBoundingClientRect() ?? { x: 0, width: 0 };

    return val - bounds.x - bounds.width / 2;
  });

  const springForumla = { mass: 0.1, stiffness: 150, damping: 12 };

  const widthRangeMap = {
    mobile: [40, 80],
    desktop: [60, 100],
  };

  const widthRange = isMobile ? widthRangeMap.mobile : widthRangeMap.desktop;

  const widthSync = useTransform(
    distance,
    [-150, 0, 150],
    [widthRange[0], widthRange[1], widthRange[0]],
  );

  const width = useSpring(widthSync, springForumla);

  return (
    <Tooltip clientOnlyRender={false}>
      <TooltipTrigger>
        <motion.div
          ref={(node) => {
            if (ref) {
              ref.current = node;
            }
          }}
          style={{
            width: isMobile ? 60 : width,
            backgroundImage: `
              linear-gradient(
                45deg,
                hsl(0 0% 97.3%),
                hsl(0 0% 90.9%),
                hsl(0 0% 97.3%),
                hsl(0 0% 90.9%)
              )
              `,
          }}
          className="aspect-square w-10 rounded-full flex items-center justify-center text-gray-500"
        >
          <div className="w-[50%] max-w-[100px] max-h-[100px]">{children}</div>
        </motion.div>
      </TooltipTrigger>

      <TooltipContent className="bg-white text-gray-700 rounded-xl shadow-2xl text-base p-2 border-[1px] border-gray-300 uppercase">
        {tooltipName}
      </TooltipContent>
    </Tooltip>
  );
}
